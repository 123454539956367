<template>
	<div>
		<a-config-provider :locale="locale">
			<router-view v-if="isRouterLive"></router-view>
		</a-config-provider>
	</div>
</template>

<script>
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import { reactive, toRefs,nextTick,provide } from 'vue';
import store from '@/store'
export default {
	name: 'App',
	setup(){
		const state = reactive({
			locale: zhCN,
			isRouterLive:true,
		})

		const onRefresh = () => {
			state.isRouterLive = false
			nextTick(() => {
				state.isRouterLive = true
			})
		}
		provide('reload', onRefresh)

		if (!!window.ActiveXObject || "ActiveXObject" in window){
			alert('请使用谷歌浏览器')
		}else {
			console.log('no')
		}

		//通过localStorage获取vuex里islogin的状态
		if ( localStorage.getItem("token")&& localStorage.getItem("userId") ){
			store.dispatch("setUsername",localStorage.getItem("loginname"));
			store.dispatch("setToken",localStorage.getItem("token"));
			store.dispatch("setUserId",localStorage.getItem("userId"));
			store.dispatch("setUserInfo",JSON.parse(localStorage.getItem("kdFarmUserInfo")) );
		} else {
			store.dispatch("setToken",null)
			store.dispatch("setUserId",null)
			store.dispatch("setUsername",null)
			store.dispatch('setPermissionList',null)
			localStorage.setItem('token','')
			localStorage.setItem('userId','')
			localStorage.setItem('loginname','')
		}

		return{
			...toRefs(state)
		}
	}
}
</script>

<style lang="scss">
	.el-content{
		padding: 12px;
		background: #fff;
		animation: fade-in;/*动画名称*/
		animation-duration: .5s;/*动画持续时间*/
		-webkit-animation:fade-in .5s;/*针对webkit内核*/

		.kd-pacel{
			color: #000;
			font-weight: bold;
			margin-bottom: 12px;
		}
		.tb-overflow{
			.ant-table-body{
				overflow-x: auto !important;
			}
		}
	}
    .tips{
        width: 100%;
        padding: 8px 16px;
        background-color: #ddedf7;
        border-radius: 4px;
        border-left: 5px solid #50bfff;
        margin-bottom: 12px;
        a{
            color: #177CE3;
            text-decoration: none;
        }
        p{
            color: #868686;
            margin: 2px;
            font-size: 12px;
            line-height: 24px;
        }
    }
	@keyframes fade-in {
		0% {opacity: 0;transform: translateX(5vh);}/*初始状态 透明度为0*/
		20% {opacity: .2;transform: translateX(4vh);}/*初始状态 透明度为0*/
		40% {opacity: .4;transform: translateX(3vh);}/*初始状态 透明度为0*/
		60% {opacity: .6;transform: translateX(2vh);}
		80% {opacity: .8;transform: translateX(1vh);}
		100% {opacity: 1;transform: none;}/*结束状态 透明度为1*/
	}
	@-webkit-keyframes fade-in {/*针对webkit内核*/
		0% {opacity: 0;transform: translateX(5vh);}/*初始状态 透明度为0*/
		20% {opacity: .2;transform: translateX(4vh);}/*初始状态 透明度为0*/
		40% {opacity: .4;transform: translateX(3vh);}/*初始状态 透明度为0*/
		60% {opacity: .6;transform: translateX(2vh);}
		80% {opacity: .8;transform: translateX(1vh);}
		100% {opacity: 1;transform: none;}/*结束状态 透明度为1*/
	}
	.pager{
		width: 100%;
		text-align: right;
		margin-top: 12px;
	}
	.f12{
		color: #999;
		font-size: 12px;
	}
	.f13{
		color: #666;
		font-size: 13px;
	}
	.ri-top{
		position: relative;
		top: 3px;
	}
	.ri-btn{
		margin-right: 4px;
		position: relative;
		top: 1px;
	}
	.mb12{
		margin-bottom: 12px !important;
	}
	.lh16{
		line-height: 16px;
	}
	.kdred{
		color: #FF0066;
	}
	.kdgreen{
		color: #00CC66;
	}
	.pt{
		cursor: pointer !important;
	}

</style>
